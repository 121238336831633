import React from "react"
import {
  Background,
  BackgroundFilter,
  CoreValuesItemContainer,
  CoreValuesItemDescription,
  CoreValuesTitle,
} from "./CoreValuesCardItem.style"
import useWindowDimensions from "@hooks/useWindowDimensions"

const CoreValuesCardItem = ({
  backgroundImage,
  title,
  description,
  rootRef,
}) => {
  const { isMobile } = useWindowDimensions()

  return (
    <CoreValuesItemContainer ref={rootRef} className="core-values__items">
      <Background src={backgroundImage} />
      <BackgroundFilter />
      {isMobile && <CoreValuesTitle>{title}</CoreValuesTitle>}
      <CoreValuesItemDescription>{description}</CoreValuesItemDescription>
    </CoreValuesItemContainer>
  )
}

export default CoreValuesCardItem
