import React from "react"
import {
  CoreValuesCardsWrapper,
  CoreValuesContainers,
  CoreValuesContainersInner,
  CoreValuesHeading,
  CoreValuesHeadingWrapper,
} from "./CoreValuesSection.style"
import gsap from "gsap"
import { useState } from "react"
import useWindowDimensions from "@hooks/useWindowDimensions"
import { useLayoutEffect } from "react"
import { useCallback } from "react"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import useComponentsGenerator from "@hooks/useComponentsGenerator"
import { useMemo } from "react"
import CoreValuesCardItem from "./CoreValuesCardItem/CoreValuesCardItem"
import StagesMenu from "./StagesMenu/StagesMenu"
import { CORE_VALUES } from "../../constants"

const HEADER_HALF_HEIGHT = 50
const CORE_VALUE_CARD_HEIGHT = 600

const CoreValuesSction = () => {
  const [isSectionLoaded, setIsSectionLoaded] = useState(false)

  const { isMobile } = useWindowDimensions()
  const [componentElements, generateComponents] =
    useComponentsGenerator(CORE_VALUES)

  const startPosition = useMemo(() => {
    if (isMobile) {
      return `top top`
    }
    return `center center+=${HEADER_HALF_HEIGHT}px`
  }, [isMobile])

  const isLoadRef = useCallback(node => {
    if (node !== null) {
      setIsSectionLoaded(true)
    }
  })

  useLayoutEffect(() => {
    if (!isSectionLoaded) {
      return
    }

    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)
    const sections = gsap.utils.toArray(".core-values__items")
    const stageItems = gsap.utils.toArray(".stage__item")

    setTimeout(() => {
      gsap.to(sections, {
        ease: "none",
        scrollTrigger: {
          trigger: "#core-values-section",
          pin: true,
          start: startPosition,
          end: () => `+=${(CORE_VALUE_CARD_HEIGHT / 2) * CORE_VALUES.length}`,
          onUpdate: self => {
            const frameLength = 1 / (sections.length - 1)
            // 1 - entire animation length, so length of 1 frame is calculated by dividing entire length by the number of frames

            const currentSection = Math.round(
              Math.floor(self.progress * 100) / 100 / frameLength
            )

            if (self.direction > 0 && currentSection > 0) {
              gsap.set(sections[currentSection], {
                opacity: 1,
              })
              gsap.set(sections[currentSection - 1], {
                opacity: 0,
              })

              gsap.set(stageItems[currentSection], {
                opacity: 1,
              })
              gsap.set(stageItems[currentSection - 1], {
                opacity: 0.5,
              })
            } else if (self.direction < 0 && currentSection > 0) {
              gsap.set(sections[currentSection + 1], {
                opacity: 0,
              })
              gsap.set(sections[currentSection], {
                opacity: 1,
              })

              gsap.set(stageItems[currentSection], {
                opacity: 0.5,
              })
              gsap.set(stageItems[currentSection - 1], {
                opacity: 1,
              })
            }
          },
        },
      })
    }, 1000)
  }, [isSectionLoaded])

  const generateItems = useMemo(() => {
    generateComponents((coreValue, index) => {
      componentElements.push(
        <CoreValuesCardItem
          key={index}
          backgroundImage={coreValue.backgroundImage}
          title={coreValue.title}
          description={coreValue.description}
          rootRef={isLoadRef}
        />
      )
    })

    return componentElements
  }, [])

  const stages = useMemo(() => CORE_VALUES.map(value => value.title), [])

  return (
    <>
      <CoreValuesCardsWrapper id="core-values-section">
        <CoreValuesContainers>
          {!isMobile && (
            <CoreValuesHeadingWrapper>
              <CoreValuesHeading>Core values</CoreValuesHeading>
            </CoreValuesHeadingWrapper>
          )}
          <CoreValuesContainersInner>
            {!isMobile && <StagesMenu stages={stages} />}
            {isMobile && <CoreValuesHeading>Core values</CoreValuesHeading>}
            <CoreValuesCardsWrapper>{generateItems}</CoreValuesCardsWrapper>
          </CoreValuesContainersInner>
        </CoreValuesContainers>
      </CoreValuesCardsWrapper>
    </>
  )
}

export default CoreValuesSction
