import styled from "styled-components"
import { MOBILE_MIN_WIDTH, TABLET_MAX_WIDTH } from "../../../../constants"

export const CoreValuesWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CoreValuesContainers = styled.div`
  padding: 8rem 10.813rem;
  max-width: 69.625rem;
  margin: auto;

  @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
    padding: 3rem 1.625rem 0;
    max-width: 100%;
  }

  @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
    padding: 0 1.625rem;
  }
`

export const CoreValuesContainersInner = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5.5rem;

  @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
    display: flex;
    flex-direction: column;
    row-gap: 5rem;
  }
`

export const CoreValuesCardsWrapper = styled.div`
  position: relative;
`

export const CoreValuesHeading = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;

  @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
    font-size: 1.5rem;
  }
`

export const CoreValuesHeadingWrapper = styled.h2`
  margin: 2.5rem 0;

  @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
    margin-bottom: 0;
  }
`
