import styled from "styled-components"

export const StagesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StageItemWrapper = styled.div`
  &:not(&:last-child) {
    border-bottom: 1px solid #563b5d;
  }

  &:not(&:first-child) h2 {
    opacity: 0.5;
  }
`

export const StageItem = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  padding: 2rem 0;
  margin: 0;
`
