import styled from "styled-components"
import { MOBILE_MIN_WIDTH } from "../../../../../constants"

export const Background = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  object-fit: cover;
`

export const BackgroundFilter = styled.div`
  position: absolute;
  opacity: 0.1;
  inset: 0;
  z-index: 100;
  background: linear-gradient(26deg, #2b0934 22.36%, #3d0f49 74.61%);
  backdrop-filter: brightness(0.6);
  mix-blend-mode: plus-lighter;
`

export const CoreValuesTitle = styled.h4`
  font-size: 2.125rem;
  font-weight: bold;
  margin: 0;
`

export const CoreValuesItemDescription = styled.p`
  font-size: 1.188rem;
  font-weight: normal;
`

export const CoreValuesItemContainer = styled.div`
  width: 31.5rem;
  height: 32.25rem;
  border: 1px solid #563b5d;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  padding: 2.5rem 2.875rem;
  overflow: hidden;
  box-sizing: border-box;
  transition: 0.6s;

  &:not(&:first-child) {
    opacity: 0;
  }

  @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
    width: calc(100vw - 3rem);
    height: 34.25rem;
    transform: translate(0, 0);
    left: 0;
    top: 0;
    padding: 1.5rem;
    justify-content: space-between;
  }
`
