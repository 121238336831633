import React from "react"
import { useMemo } from "react"
import {
  StageItem,
  StageItemWrapper,
  StagesContainer,
} from "./StagesMenu.style"

const StagesMenu = ({ stages }) => {
  const stageItems = useMemo(() => {
    return stages.map((stage, index) => (
      <StageItemWrapper key={index}>
        <StageItem className="stage__item">{stage}</StageItem>
      </StageItemWrapper>
    ))
  }, [stages])

  return <StagesContainer>{stageItems}</StagesContainer>
}

export default StagesMenu
